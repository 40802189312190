<template>
	<div>
		<pui-datatable v-if="!masterDetail" :modelName="modelName" :modelColumnDefs="modelColumnDefs" :actions="actions"></pui-datatable>
		<pui-datatable
			v-else
			:modelName="modelName"
			:actions="actions"
			:externalFilter="externalFilter"
			:masterDetail="masterDetail"
			:parentModelName="parentModelName"
			:modelColumnDefs="modelColumnDefs"
			:readOnly="readOnly"
			:modalDialog="modalDialog"
			:showMasterDetailFilterListBtn="true"
			:showMasterDetailFilterBtn="true"
			:showMasterDetailSortBtn="true"
			:showMasterDetailConfBtn="true"
		></pui-datatable>
	</div>
</template>

<script>
import PuiGridDetailMixin from 'pui9-mixins/PuiGridDetailMixin';

export default {
	name: 'mshipsversion-grid',
	mixins: [PuiGridDetailMixin],
	components: {},
	data() {
		return {
			modelName: 'mshipsversion',
			modelColumnDefs: {}
		};
	}
};
</script>
